import React, { useEffect } from 'react';
const Kofi2: React.FC = () => {
    useEffect(() => {
        const renderKofi = () => {
            console.log("renderKofi:", (window as any).kofiWidgetOverlay);
            if (typeof (window as any).kofiWidgetOverlay !== 'undefined') {
                console.log("drawing");
                (window as any).kofiWidgetOverlay.draw('jxliang', {
                    'type': 'floating-chat',
                    'floating-chat.donateButton.text': 'Tip Me',
                    'floating-chat.donateButton.background-color': '#5cb85c',
                    'floating-chat.donateButton.text-color': '#fff'
                });
            }
        }
        const loadKofi = (callback: () => void) => {
            console.log("loading Kofi");
            if (!document.getElementById('kofi-widget-script')) {
                console.log("before loading:", document.getElementById('kofi-widget-script'));
                // Load the Google API script
                const script = document.createElement('script');
                script.src = 'https://storage.ko-fi.com/cdn/scripts/overlay-widget.js';
                script.id = 'kofi-widget-script';
                script.async = true;
                document.body.appendChild(script);

                console.log("after loading:", document.getElementById('kofi-widget-script'));

                setTimeout(() => {
                    callback()
                }, 2000);

                // Clean up the script tag if necessary
                return () => {
                    console.log("removing kofi script")
                    document.body.removeChild(script);
                };
            }
        }

        loadKofi(renderKofi)
    }, []);

    return (
        <></>
    );
};

export default Kofi2;