import React, { useEffect, useState } from 'react';
import { service } from "../services/mbtiService";
import Cookies from 'js-cookie';

const Login: React.FC = () => {
    const [userData, setUserData] = useState({ name: "", picture: "" });

    useEffect(() => {
        // Load the Google API script
        const script = document.createElement('script');
        script.src = 'https://accounts.google.com/gsi/client';
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);

        function parseJwt(token: any) {
            const base64Url = token.split('.')[1];
            const base64 = decodeURIComponent(atob(base64Url).split('').map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));
            return JSON.parse(base64);
        }
    
        const handleSSOResponse = (response: any) => {
            // alert('response:' + JSON.stringify(response))
            console.log('response:' + JSON.stringify(response))
            // Decode the JWT token (you can use a library like jwt-decode to parse the token)
            const userData = parseJwt(response.credential);
            // Store user data in localStorage
            localStorage.setItem('user', JSON.stringify(userData));
            // Replace the sign-in button with user information
            setUserData(userData);

            service.registerUser(response).then(response => {
                console.log("registerUser response:" + response);
                if (userData) {
                    // Set the cookie
                    Cookies.set('sub', userData.sub, { 
                        expires: 7, // expires in 7 days
                        secure: true, // only transmit over https
                        sameSite: 'none', // CSRF protection
                        domain: '.wishdiv.com' 
                    });
                    
                    // Alternatively, using the native API:
                    // document.cookie = `auth_token=${response.token}; max-age=${7*24*60*60}; secure; samesite=strict`;
                }
            });
        }
        (window as any).handleLoginResponse = (response: any) => {
            handleSSOResponse(response);
        };

        function checkLoginStatus() {
            const storedUserData = localStorage.getItem('user');
            if (storedUserData) {
                setUserData(JSON.parse(storedUserData))
            }
        }

        // Run check on page load
        checkLoginStatus();

        // Clean up the script tag if necessary
        return () => {
            document.body.removeChild(script);
            delete (window as any).handleLoginResponse;
        };
    }, []);

    const mystyle = {
        borderRadius: "50%",
        marginRight: "10px"
    };

    return (
        <>
            <div id="g_id_onload"
                data-client_id="263783060586-kuoa26bbutbq8bhnmp2ggu1051opitb7.apps.googleusercontent.com"
                // data-login_uri="https://api.it-sides.com/api/mbti/g/callback"
                // data-login_uri="http://localhost:8787/api/mbti/g/callback"
                data-ux_mode="popup"
                data-context="signin"
                data-callback="handleLoginResponse"
                data-auto_prompt="false">
            </div>

            <div className="g_id_signin" data-type="standard" style={userData.name ? { display: "none" } : {}}></div>
            <div id="user-info" style={userData.name ? { display: "flex", alignItems: "center" } : { display: "none" }}>
                <img id="user-image" src={userData.picture} alt="User avarta" width="50" height="50" style={mystyle} />
                <span id="user-name">{userData.name}</span>
            </div>
        </>
    );
};

export default Login;